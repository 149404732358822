/* eslint-disable */
import * as types from './graphql';



/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  fragment userFields on UserNode {\n    id\n    email\n    firstName\n    lastName\n    profileImage\n  }\n": types.UserFieldsFragmentDoc,
    "\n  fragment userPreferencesFields on UserPreferenceNode {\n    key\n    value\n  }\n": types.UserPreferencesFieldsFragmentDoc,
    "\n  fragment userPreferences on UserNode {\n    preferences {\n      edges {\n        node {\n          ...userPreferencesFields\n        }\n      }\n    }\n  }\n": types.UserPreferencesFragmentDoc,
    "\n  fragment collaborationMembershipFields on CollaborationMembershipNode {\n    id\n    userRole\n    membershipStatus\n    pendingEmail\n    user {\n      ...userFields\n    }\n  }\n": types.CollaborationMembershipFieldsFragmentDoc,
    "\n  fragment collaborationMembershipsPending on CollaborationMembershipListNode {\n    pending: memberships(membershipStatus: PENDING) {\n      totalCount\n    }\n  }\n": types.CollaborationMembershipsPendingFragmentDoc,
    "\n  fragment collaborationMembershipInfo on CollaborationMembershipListNode {\n    membershipType\n    membershipsCount\n    enrollMethod\n    memberships(first: 5, membershipStatus: APPROVED) {\n      totalCount\n      edges {\n        node {\n          user {\n            ...userFields\n          }\n        }\n      }\n    }\n  }\n": types.CollaborationMembershipInfoFragmentDoc,
    "\n  fragment collaborationMemberships on CollaborationMembershipListNode {\n    memberships {\n      totalCount\n      edges {\n        node {\n          ...collaborationMembershipFields\n        }\n      }\n    }\n  }\n": types.CollaborationMembershipsFragmentDoc,
    "\n  fragment accountCollaborationMembership on CollaborationMembershipListNode {\n    accountMembership {\n      id\n      userRole\n      membershipStatus\n      user {\n        ...userFields\n      }\n    }\n  }\n": types.AccountCollaborationMembershipFragmentDoc,
    "\n  fragment projectMembershipFields on ProjectMembershipNode {\n    id\n    userRole\n    user {\n      ...userFields\n      ...userPreferences\n    }\n  }\n": types.ProjectMembershipFieldsFragmentDoc,
    "\n  fragment projectMembershipList on ProjectMembershipListNode {\n    memberships {\n      totalCount\n      edges {\n        node {\n          ...projectMembershipFields\n        }\n      }\n    }\n  }\n": types.ProjectMembershipListFragmentDoc,
    "\n  fragment projectData on ProjectNode {\n    id\n    name\n    privacy\n    measurementUnits\n    description\n    siteInstructions\n    updatedAt\n    archived\n    membershipList {\n      ...projectMembershipList\n    }\n    siteSet {\n      edges {\n        node {\n          ...siteData\n        }\n      }\n    }\n    soilSettings {\n      ...projectSoilSettings\n    }\n  }\n": types.ProjectDataFragmentDoc,
    "\n  fragment siteData on SiteNode {\n    id\n    name\n    latitude\n    longitude\n    elevation\n    archived\n    updatedAt\n    privacy\n    owner {\n      id\n    }\n    project {\n      id\n    }\n    notes {\n      edges {\n        node {\n          ...siteNoteData\n        }\n      }\n    }\n  }\n": types.SiteDataFragmentDoc,
    "\n  fragment siteNoteData on SiteNoteNode {\n    id\n    content\n    createdAt\n    updatedAt\n    author {\n      id\n      firstName\n      lastName\n    }\n    site {\n      id\n    }\n  }\n": types.SiteNoteDataFragmentDoc,
    "\n  fragment projectSoilSettings on ProjectSoilSettingsNode {\n    depthIntervals {\n      depthInterval {\n        start\n        end\n      }\n      label\n    }\n    depthIntervalPreset\n    soilPitRequired\n    slopeRequired\n    soilTextureRequired\n    soilColorRequired\n    verticalCrackingRequired\n    carbonatesRequired\n    phRequired\n    soilOrganicCarbonMatterRequired\n    electricalConductivityRequired\n    sodiumAdsorptionRatioRequired\n    soilStructureRequired\n    landUseLandCoverRequired\n    soilLimitationsRequired\n    photosRequired\n    notesRequired\n  }\n": types.ProjectSoilSettingsFragmentDoc,
    "\n  fragment soilData on SoilDataNode {\n    downSlope\n    crossSlope\n    bedrock\n    depthIntervalPreset\n    slopeLandscapePosition\n    slopeAspect\n    slopeSteepnessSelect\n    slopeSteepnessPercent\n    slopeSteepnessDegree\n    surfaceCracksSelect\n    surfaceSaltSelect\n    floodingSelect\n    limeRequirementsSelect\n    surfaceStoninessSelect\n    waterTableDepthSelect\n    soilDepthSelect\n    landCoverSelect\n    grazingSelect\n    depthIntervals {\n      ...soilDataDepthInterval\n    }\n    depthDependentData {\n      ...depthDependentSoilData\n    }\n  }\n": types.SoilDataFragmentDoc,
    "\n  fragment soilDataDepthInterval on SoilDataDepthIntervalNode {\n    label\n    depthInterval {\n      start\n      end\n    }\n    soilTextureEnabled\n    soilColorEnabled\n    carbonatesEnabled\n    phEnabled\n    soilOrganicCarbonMatterEnabled\n    electricalConductivityEnabled\n    sodiumAdsorptionRatioEnabled\n    soilStructureEnabled\n  }\n": types.SoilDataDepthIntervalFragmentDoc,
    "\n  fragment depthDependentSoilData on DepthDependentSoilDataNode {\n    depthInterval {\n      start\n      end\n    }\n    texture\n    rockFragmentVolume\n    clayPercent\n    colorHue\n    colorValue\n    colorChroma\n    colorPhotoUsed\n    colorPhotoSoilCondition\n    colorPhotoLightingCondition\n    conductivity\n    conductivityTest\n    conductivityUnit\n    structure\n    ph\n    phTestingSolution\n    phTestingMethod\n    soilOrganicCarbon\n    soilOrganicMatter\n    soilOrganicCarbonTesting\n    soilOrganicMatterTesting\n    sodiumAbsorptionRatio\n    carbonates\n  }\n": types.DepthDependentSoilDataFragmentDoc,
    "\n  fragment soilMatchInfo on SoilMatchInfo {\n    score\n    rank\n  }\n": types.SoilMatchInfoFragmentDoc,
    "\n  fragment soilInfo on SoilInfo {\n    soilSeries {\n      name\n      taxonomySubgroup\n      description\n      fullDescriptionUrl\n    }\n\n    ecologicalSite {\n      name\n      id\n      url\n    }\n\n    landCapabilityClass {\n      capabilityClass\n      subClass\n    }\n\n    soilData {\n      slope\n      depthDependentData {\n        depthInterval {\n          start\n          end\n        }\n        texture\n        rockFragmentVolume\n        munsellColorString\n      }\n    }\n  }\n": types.SoilInfoFragmentDoc,
    "\n  fragment soilIdFailure on SoilIdFailure {\n    reason\n  }\n": types.SoilIdFailureFragmentDoc,
    "\n  fragment locationBasedSoilMatches on LocationBasedSoilMatches {\n    matches {\n      dataSource\n      distanceToNearestMapUnitM\n      match {\n        ...soilMatchInfo\n      }\n      soilInfo {\n        ...soilInfo\n      }\n    }\n  }\n": types.LocationBasedSoilMatchesFragmentDoc,
    "\n  fragment dataBasedSoilMatches on DataBasedSoilMatches {\n    matches {\n      dataSource\n      distanceToNearestMapUnitM\n      locationMatch {\n        ...soilMatchInfo\n      }\n      dataMatch {\n        ...soilMatchInfo\n      }\n      combinedMatch {\n        ...soilMatchInfo\n      }\n      soilInfo {\n        ...soilInfo\n      }\n    }\n  }\n": types.DataBasedSoilMatchesFragmentDoc,
    "\n  fragment groupFields on GroupNode {\n    id\n    slug\n    name\n    description\n    email\n    website\n    email\n  }\n": types.GroupFieldsFragmentDoc,
    "\n  fragment groupsListFields on GroupNode {\n    slug\n    name\n  }\n": types.GroupsListFieldsFragmentDoc,
    "\n  fragment groupDataEntries on GroupNode {\n    sharedResources {\n      edges {\n        node {\n          id\n          shareAccess\n          shareUrl\n          source {\n            ... on DataEntryNode {\n              ...dataEntry\n              ...dataEntryVisualizations\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GroupDataEntriesFragmentDoc,
    "\n  fragment groupMembershipList on GroupNode {\n    membershipList {\n      membershipType\n      membershipsCount\n      ...accountCollaborationMembership\n    }\n  }\n": types.GroupMembershipListFragmentDoc,
    "\n  fragment groupMembershipListWithMembersSample on GroupNode {\n    membershipList {\n      membershipType\n      membershipsCount\n      ...accountCollaborationMembership\n      ...collaborationMembershipsPending\n      memberships(\n        user_Email_Not: $accountEmail\n        first: 5\n        membershipStatus: APPROVED\n      ) {\n        edges {\n          node {\n            ...collaborationMembershipFields\n          }\n        }\n      }\n    }\n  }\n": types.GroupMembershipListWithMembersSampleFragmentDoc,
    "\n    query groupToUpdate($slug: String!) {\n      groups(slug: $slug) {\n        edges {\n          node {\n            ...groupFields\n            ...groupMembershipList\n          }\n        }\n      }\n    }\n  ": types.GroupToUpdateDocument,
    "\n    query groupToView($slug: String!, $accountEmail: String!) {\n      groups(slug: $slug) {\n        edges {\n          node {\n            ...groupFields\n            ...groupDataEntries\n            ...groupMembershipListWithMembersSample\n          }\n        }\n      }\n    }\n  ": types.GroupToViewDocument,
    "\n    query groups {\n      independentGroups: groups(associatedLandscapes_Isnull: true) {\n        edges {\n          node {\n            ...groupFields\n            ...groupMembershipList\n          }\n        }\n      }\n      landscapeGroups: groups(associatedLandscapes_Isnull: false) {\n        edges {\n          node {\n            ...groupFields\n            ...groupMembershipList\n          }\n        }\n      }\n    }\n  ": types.GroupsDocument,
    "\n    query groupsAutocomplete {\n      independentGroups: groups(associatedLandscapes_Isnull: true) {\n        edges {\n          node {\n            ...groupsListFields\n          }\n        }\n      }\n      landscapeGroups: groups(associatedLandscapes_Isnull: false) {\n        edges {\n          node {\n            ...groupsListFields\n          }\n        }\n      }\n    }\n  ": types.GroupsAutocompleteDocument,
    "\n    query groupForMembers($slug: String!) {\n      groups(slug: $slug) {\n        edges {\n          node {\n            ...groupFields\n            membershipList {\n              ...collaborationMemberships\n              ...accountCollaborationMembership\n            }\n          }\n        }\n      }\n    }\n  ": types.GroupForMembersDocument,
    "\n    query groupToUploadSharedData($slug: String!) {\n      groups(slug: $slug) {\n        edges {\n          node {\n            id\n            slug\n            name\n            ...groupMembershipList\n          }\n        }\n      }\n    }\n  ": types.GroupToUploadSharedDataDocument,
    "\n    mutation updateGroup($input: GroupUpdateMutationInput!) {\n      updateGroup(input: $input) {\n        group {\n          ...groupFields\n        }\n        errors\n      }\n    }\n  ": types.UpdateGroupDocument,
    "\n    mutation addGroup($input: GroupAddMutationInput!) {\n      addGroup(input: $input) {\n        group {\n          ...groupFields\n        }\n        errors\n      }\n    }\n  ": types.AddGroupDocument,
    "\n    mutation deleteGroupMembership(\n      $input: GroupMembershipDeleteMutationInput!\n      $accountEmail: String!\n    ) {\n      deleteGroupMembership(input: $input) {\n        group {\n          ...groupFields\n          ...groupDataEntries\n          ...groupMembershipListWithMembersSample\n        }\n        errors\n      }\n    }\n  ": types.DeleteGroupMembershipDocument,
    "\n    mutation joinGroup(\n      $input: GroupMembershipSaveMutationInput!\n      $accountEmail: String!\n    ) {\n      saveGroupMembership(input: $input) {\n        group {\n          ...groupFields\n          ...groupDataEntries\n          ...groupMembershipListWithMembersSample\n        }\n        errors\n      }\n    }\n  ": types.JoinGroupDocument,
    "\n    mutation leaveGroupFromList($input: GroupMembershipDeleteMutationInput!) {\n      deleteGroupMembership(input: $input) {\n        group {\n          ...groupFields\n          ...groupMembershipList\n        }\n        errors\n      }\n    }\n  ": types.LeaveGroupFromListDocument,
    "\n    mutation joinGroupFromListPage($input: GroupMembershipSaveMutationInput!) {\n      saveGroupMembership(input: $input) {\n        group {\n          ...groupFields\n          ...groupMembershipList\n        }\n        errors\n      }\n    }\n  ": types.JoinGroupFromListPageDocument,
    "\n    mutation changeGroupMemberRole($input: GroupMembershipSaveMutationInput!) {\n      saveGroupMembership(input: $input) {\n        memberships {\n          ...collaborationMembershipFields\n        }\n        errors\n      }\n    }\n  ": types.ChangeGroupMemberRoleDocument,
    "\n    mutation removeGroupMember($input: GroupMembershipDeleteMutationInput!) {\n      deleteGroupMembership(input: $input) {\n        membership {\n          ...collaborationMembershipFields\n        }\n        errors\n      }\n    }\n  ": types.RemoveGroupMemberDocument,
    "\n    query home($accountEmail: String!) {\n      landscapes(membershipList_Memberships_User_Email: $accountEmail) {\n        edges {\n          node {\n            ...landscapeFields\n            ...landscapeMembershipList\n          }\n        }\n      }\n      userIndependentGroups: groups(\n        memberships_Email: $accountEmail\n        associatedLandscapes_Isnull: true\n      ) {\n        edges {\n          node {\n            ...groupFields\n            membershipList {\n              ...collaborationMembershipsPending\n              ...accountCollaborationMembership\n            }\n          }\n        }\n      }\n      userLandscapeGroups: groups(\n        memberships_Email: $accountEmail\n        associatedLandscapes_Isnull: false\n      ) {\n        edges {\n          node {\n            ...groupFields\n            membershipList {\n              ...collaborationMembershipsPending\n              ...accountCollaborationMembership\n            }\n          }\n        }\n      }\n      storyMaps(memberships_User_Email: $accountEmail) {\n        edges {\n          node {\n            ...storyMapMetadataFields\n          }\n        }\n      }\n    }\n  ": types.HomeDocument,
    "\n  fragment landscapeFields on LandscapeNode {\n    id\n    slug\n    name\n    location\n    description\n    email\n    website\n    profileImage\n  }\n": types.LandscapeFieldsFragmentDoc,
    "\n  fragment landscapeProfileFields on LandscapeNode {\n    id\n    slug\n    name\n    location\n    description\n    email\n    website\n    areaScalarHa\n    areaTypes\n    population\n    partnershipStatus\n    profileImage\n    profileImageDescription\n    taxonomyTerms {\n      edges {\n        node {\n          type\n          valueOriginal\n          ...taxonomyTermLanguages\n        }\n      }\n    }\n    associatedGroups {\n      edges {\n        node {\n          isPartnership\n          partnershipYear\n          group {\n            slug\n            name\n          }\n        }\n      }\n    }\n    associatedDevelopmentStrategy {\n      edges {\n        node {\n          objectives\n          opportunities\n          problemSitutation\n          interventionStrategy\n        }\n      }\n    }\n  }\n": types.LandscapeProfileFieldsFragmentDoc,
    "\n  fragment landscapePartnershipField on LandscapeNode {\n    partnershipStatus\n    associatedGroups(isPartnership: true) {\n      edges {\n        node {\n          isPartnership\n          partnershipYear\n          group {\n            slug\n            name\n          }\n        }\n      }\n    }\n  }\n": types.LandscapePartnershipFieldFragmentDoc,
    "\n  fragment landscapeMembershipList on LandscapeNode {\n    membershipList {\n      membershipsCount\n      ...accountCollaborationMembership\n    }\n  }\n": types.LandscapeMembershipListFragmentDoc,
    "\n  fragment landscapeMembershipListWithMembersSample on LandscapeNode {\n    membershipList {\n      membershipsCount\n      ...accountCollaborationMembership\n      memberships(\n        user_Email_Not: $accountEmail\n        first: 5\n        membershipStatus: APPROVED\n      ) {\n        edges {\n          node {\n            ...collaborationMembershipFields\n          }\n        }\n      }\n    }\n  }\n": types.LandscapeMembershipListWithMembersSampleFragmentDoc,
    "\n  fragment landscapeDataEntries on LandscapeNode {\n    sharedResources {\n      edges {\n        node {\n          id\n          shareAccess\n          shareUrl\n          source {\n            ... on DataEntryNode {\n              ...dataEntry\n              ...dataEntryVisualizations\n            }\n          }\n        }\n      }\n    }\n  }\n": types.LandscapeDataEntriesFragmentDoc,
    "\n    query landscapesToUpdate($slug: String!) {\n      landscapes(slug: $slug) {\n        edges {\n          node {\n            ...landscapeProfileFields\n            areaPolygon\n          }\n        }\n      }\n    }\n  ": types.LandscapesToUpdateDocument,
    "\n    query landscapesToView($slug: String!, $accountEmail: String!) {\n      landscapes(slug: $slug) {\n        edges {\n          node {\n            ...landscapeFields\n            ...landscapePartnershipField\n            ...landscapeDataEntries\n            ...landscapeMembershipListWithMembersSample\n            areaPolygon\n          }\n        }\n      }\n    }\n  ": types.LandscapesToViewDocument,
    "\n    query landscapesProfiles($slug: String!) {\n      landscapes(slug: $slug) {\n        edges {\n          node {\n            ...landscapeProfileFields\n            ...landscapeMembershipList\n          }\n        }\n      }\n    }\n  ": types.LandscapesProfilesDocument,
    "\n    query landscapesToUploadSharedData($slug: String!) {\n      landscapes(slug: $slug) {\n        edges {\n          node {\n            ...landscapeFields\n            ...landscapeMembershipList\n          }\n        }\n      }\n    }\n  ": types.LandscapesToUploadSharedDataDocument,
    "\n    query landscapes {\n      landscapes {\n        edges {\n          node {\n            ...landscapeFields\n            ...landscapeMembershipList\n            centerCoordinates {\n              lat\n              lng\n            }\n          }\n        }\n      }\n    }\n  ": types.LandscapesDocument,
    "\n    query landscapesForMembers($slug: String!) {\n      landscapes(slug: $slug) {\n        edges {\n          node {\n            ...landscapeFields\n            membershipList {\n              ...collaborationMemberships\n              ...accountCollaborationMembership\n            }\n          }\n        }\n      }\n    }\n  ": types.LandscapesForMembersDocument,
    "\n    mutation updateLandscape($input: LandscapeUpdateMutationInput!) {\n      updateLandscape(input: $input) {\n        landscape {\n          ...landscapeProfileFields\n        }\n        errors\n      }\n    }\n  ": types.UpdateLandscapeDocument,
    "\n    mutation addLandscape($input: LandscapeAddMutationInput!) {\n      addLandscape(input: $input) {\n        landscape {\n          ...landscapeProfileFields\n        }\n        errors\n      }\n    }\n  ": types.AddLandscapeDocument,
    "\n    mutation deleteLandscapeMembership(\n      $input: LandscapeMembershipDeleteMutationInput!\n      $accountEmail: String!\n    ) {\n      deleteLandscapeMembership(input: $input) {\n        landscape {\n          ...landscapeFields\n          ...landscapePartnershipField\n          ...landscapeDataEntries\n          ...landscapeMembershipListWithMembersSample\n          areaPolygon\n        }\n        errors\n      }\n    }\n  ": types.DeleteLandscapeMembershipDocument,
    "\n    mutation joinLandscape(\n      $input: LandscapeMembershipSaveMutationInput!\n      $accountEmail: String!\n    ) {\n      saveLandscapeMembership(input: $input) {\n        landscape {\n          ...landscapeFields\n          ...landscapePartnershipField\n          ...landscapeDataEntries\n          ...landscapeMembershipListWithMembersSample\n          areaPolygon\n        }\n        errors\n      }\n    }\n  ": types.JoinLandscapeDocument,
    "\n    mutation leaveLandscapeFromList(\n      $input: LandscapeMembershipDeleteMutationInput!\n    ) {\n      deleteLandscapeMembership(input: $input) {\n        landscape {\n          ...landscapeFields\n          ...landscapeMembershipList\n          centerCoordinates {\n            lat\n            lng\n          }\n        }\n        errors\n      }\n    }\n  ": types.LeaveLandscapeFromListDocument,
    "\n    mutation joinLandscapeFromList(\n      $input: LandscapeMembershipSaveMutationInput!\n    ) {\n      saveLandscapeMembership(input: $input) {\n        landscape {\n          ...landscapeFields\n          ...landscapeMembershipList\n          centerCoordinates {\n            lat\n            lng\n          }\n        }\n        errors\n      }\n    }\n  ": types.JoinLandscapeFromListDocument,
    "\n    mutation changeMemberRole($input: LandscapeMembershipSaveMutationInput!) {\n      saveLandscapeMembership(input: $input) {\n        memberships {\n          ...collaborationMembershipFields\n        }\n        errors\n      }\n    }\n  ": types.ChangeMemberRoleDocument,
    "\n    mutation removeMember($input: LandscapeMembershipDeleteMutationInput!) {\n      deleteLandscapeMembership(input: $input) {\n        membership {\n          ...collaborationMembershipFields\n        }\n        errors\n      }\n    }\n  ": types.RemoveMemberDocument,
    "\n  fragment dataEntry on DataEntryNode {\n    id\n    name\n    description\n    entryType\n    resourceType\n    url\n    size\n    createdAt\n    createdBy {\n      id\n      lastName\n      firstName\n    }\n  }\n": types.DataEntryFragmentDoc,
    "\n  fragment dataEntryVisualizations on DataEntryNode {\n    visualizations {\n      edges {\n        node {\n          ...visualizationConfig\n        }\n      }\n    }\n  }\n": types.DataEntryVisualizationsFragmentDoc,
    "\n  fragment visualizationConfig on VisualizationConfigNode {\n    id\n    readableId\n    title\n    description\n    slug\n    createdAt\n    createdBy {\n      id\n      lastName\n      firstName\n    }\n  }\n": types.VisualizationConfigFragmentDoc,
    "\n  fragment visualizationConfigWithConfiguration on VisualizationConfigNode {\n    ...visualizationConfig\n    mapboxTilesetId\n    mapboxTilesetStatus\n    configuration\n  }\n": types.VisualizationConfigWithConfigurationFragmentDoc,
    "\n    mutation deleteSharedData($id: ID!) {\n      deleteDataEntry(input: { id: $id }) {\n        dataEntry {\n          id\n        }\n        errors\n      }\n    }\n  ": types.DeleteSharedDataDocument,
    "\n    mutation addDataEntry($input: DataEntryAddMutationInput!) {\n      addDataEntry(input: $input) {\n        dataEntry {\n          id\n          name\n          url\n        }\n        errors\n      }\n    }\n  ": types.AddDataEntryDocument,
    "\n    mutation updateSharedData($input: DataEntryUpdateMutationInput!) {\n      updateDataEntry(input: $input) {\n        dataEntry {\n          ...dataEntry\n          visualizations {\n            edges {\n              node {\n                ...visualizationConfigWithConfiguration\n              }\n            }\n          }\n        }\n        errors\n      }\n    }\n  ": types.UpdateSharedDataDocument,
    "\n    query dataEntries(\n      $slug: String!\n      $type: String!\n      $resourceTypes: [String]!\n    ) {\n      dataEntries(\n        sharedResources_Target_Slug: $slug\n        sharedResources_TargetContentType: $type\n        resourceType_In: $resourceTypes\n      ) {\n        edges {\n          node {\n            ...dataEntry\n            ...dataEntryVisualizations\n          }\n        }\n      }\n    }\n  ": types.DataEntriesDocument,
    "\n    query dataEntryWithGeojson($id: ID!) {\n      dataEntry(id: $id) {\n        ...dataEntry\n        geojson\n      }\n    }\n  ": types.DataEntryWithGeojsonDocument,
    "\n    mutation addVisualizationConfig(\n      $input: VisualizationConfigAddMutationInput!\n    ) {\n      addVisualizationConfig(input: $input) {\n        visualizationConfig {\n          slug\n          readableId\n        }\n        errors\n      }\n    }\n  ": types.AddVisualizationConfigDocument,
    "\n    mutation deleteVisualizationConfig($id: ID!) {\n      deleteVisualizationConfig(input: { id: $id }) {\n        visualizationConfig {\n          ...visualizationConfig\n        }\n        errors\n      }\n    }\n  ": types.DeleteVisualizationConfigDocument,
    "\n    query fetchVisualizationConfig(\n      $ownerSlug: String!\n      $ownerType: String!\n      $configSlug: String!\n      $readableId: String!\n    ) {\n      visualizationConfigs(\n        dataEntry_SharedResources_Target_Slug: $ownerSlug\n        dataEntry_SharedResources_TargetContentType: $ownerType\n        slug: $configSlug\n        readableId: $readableId\n      ) {\n        edges {\n          node {\n            ...visualizationConfigWithConfiguration\n            dataEntry {\n              ...dataEntry\n            }\n          }\n        }\n      }\n    }\n  ": types.FetchVisualizationConfigDocument,
    "\n    mutation updateSharedResource($input: SharedResourceUpdateMutationInput!) {\n      updateSharedResource(input: $input) {\n        sharedResource {\n          id\n          shareAccess\n          shareUrl\n          source {\n            ... on DataEntryNode {\n              ...dataEntry\n              ...dataEntryVisualizations\n            }\n          }\n        }\n        errors\n      }\n    }\n  ": types.UpdateSharedResourceDocument,
    "\n    query sharedResource($shareUuid: String!) {\n      sharedResource(shareUuid: $shareUuid) {\n        downloadUrl\n        source {\n          ... on DataEntryNode {\n            ...dataEntry\n          }\n        }\n      }\n    }\n  ": types.SharedResourceDocument,
    "\n  fragment storyMapFields on StoryMapNode {\n    id\n    slug\n    storyMapId\n    title\n    isPublished\n    configuration\n    createdAt\n    updatedAt\n    publishedAt\n    createdBy {\n      ...userFields\n    }\n    membershipList {\n      ...collaborationMemberships\n      ...accountCollaborationMembership\n    }\n  }\n": types.StoryMapFieldsFragmentDoc,
    "\n  fragment storyMapMetadataFields on StoryMapNode {\n    id\n    slug\n    storyMapId\n    title\n    isPublished\n    createdAt\n    updatedAt\n    publishedAt\n    createdBy {\n      id\n      lastName\n      firstName\n    }\n    membershipList {\n      membershipsCount\n      ...accountCollaborationMembership\n      memberships(user_Email_Not: $accountEmail, first: 2) {\n        edges {\n          node {\n            ...collaborationMembershipFields\n          }\n        }\n      }\n    }\n  }\n": types.StoryMapMetadataFieldsFragmentDoc,
    "\n    query storyMapsHome($accountEmail: String!) {\n      samples: storyMaps(memberships_User_Email_Not: $accountEmail) {\n        edges {\n          node {\n            ...storyMapMetadataFields\n          }\n        }\n      }\n      userStoryMaps: storyMaps(memberships_User_Email: $accountEmail) {\n        edges {\n          node {\n            ...storyMapMetadataFields\n          }\n        }\n      }\n    }\n  ": types.StoryMapsHomeDocument,
    "\n    query fetchStoryMap($slug: String!, $storyMapId: String!) {\n      storyMaps(slug: $slug, storyMapId: $storyMapId) {\n        edges {\n          node {\n            ...storyMapFields\n            membershipList {\n              ...collaborationMemberships\n              ...accountCollaborationMembership\n            }\n          }\n        }\n      }\n    }\n  ": types.FetchStoryMapDocument,
    "\n    mutation deleteStoryMap($id: ID!) {\n      deleteStoryMap(input: { id: $id }) {\n        storyMap {\n          id\n        }\n        errors\n      }\n    }\n  ": types.DeleteStoryMapDocument,
    "\n    mutation addMemberships($input: StoryMapMembershipSaveMutationInput!) {\n      saveStoryMapMembership(input: $input) {\n        memberships {\n          ...collaborationMembershipFields\n        }\n        errors\n      }\n    }\n  ": types.AddMembershipsDocument,
    "\n    mutation deleteMembership($input: StoryMapMembershipDeleteMutationInput!) {\n      deleteStoryMapMembership(input: $input) {\n        membership {\n          id\n        }\n        errors\n      }\n    }\n  ": types.DeleteMembershipDocument,
    "\n    mutation approveMembership(\n      $accountEmail: String!\n      $input: StoryMapMembershipApproveMutationInput!\n    ) {\n      approveStoryMapMembership(input: $input) {\n        membership {\n          id\n        }\n        storyMap {\n          ...storyMapMetadataFields\n        }\n        errors\n      }\n    }\n  ": types.ApproveMembershipDocument,
    "\n    mutation approveMembershipToken(\n      $accountEmail: String!\n      $input: StoryMapMembershipApproveTokenMutationInput!\n    ) {\n      approveStoryMapMembershipToken(input: $input) {\n        membership {\n          id\n        }\n        storyMap {\n          ...storyMapMetadataFields\n        }\n        errors\n      }\n    }\n  ": types.ApproveMembershipTokenDocument,
    "\n    query visualizationConfigs {\n      visualizationConfigs {\n        edges {\n          node {\n            ...visualizationConfigWithConfiguration\n            dataEntry {\n              name\n              resourceType\n              createdBy {\n                lastName\n                firstName\n              }\n              sharedResources {\n                edges {\n                  node {\n                    target {\n                      ... on GroupNode {\n                        name\n                        membershipList {\n                          membershipType\n                        }\n                      }\n                      ... on LandscapeNode {\n                        name\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  ": types.VisualizationConfigsDocument,
    "\n  fragment taxonomyTermLanguages on TaxonomyTermNode {\n    valueEn\n    valueEs\n  }\n": types.TaxonomyTermLanguagesFragmentDoc,
    "\n    query taxonomyTerms($types: [CoreTaxonomyTermTypeChoices]!) {\n      taxonomyTerms(type_In: $types) {\n        edges {\n          node {\n            slug\n            type\n            valueOriginal\n            ...taxonomyTermLanguages\n          }\n        }\n      }\n    }\n  ": types.TaxonomyTermsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment userFields on UserNode {\n    id\n    email\n    firstName\n    lastName\n    profileImage\n  }\n"): typeof import('./graphql').UserFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment userPreferencesFields on UserPreferenceNode {\n    key\n    value\n  }\n"): typeof import('./graphql').UserPreferencesFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment userPreferences on UserNode {\n    preferences {\n      edges {\n        node {\n          ...userPreferencesFields\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').UserPreferencesFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment collaborationMembershipFields on CollaborationMembershipNode {\n    id\n    userRole\n    membershipStatus\n    pendingEmail\n    user {\n      ...userFields\n    }\n  }\n"): typeof import('./graphql').CollaborationMembershipFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment collaborationMembershipsPending on CollaborationMembershipListNode {\n    pending: memberships(membershipStatus: PENDING) {\n      totalCount\n    }\n  }\n"): typeof import('./graphql').CollaborationMembershipsPendingFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment collaborationMembershipInfo on CollaborationMembershipListNode {\n    membershipType\n    membershipsCount\n    enrollMethod\n    memberships(first: 5, membershipStatus: APPROVED) {\n      totalCount\n      edges {\n        node {\n          user {\n            ...userFields\n          }\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').CollaborationMembershipInfoFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment collaborationMemberships on CollaborationMembershipListNode {\n    memberships {\n      totalCount\n      edges {\n        node {\n          ...collaborationMembershipFields\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').CollaborationMembershipsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment accountCollaborationMembership on CollaborationMembershipListNode {\n    accountMembership {\n      id\n      userRole\n      membershipStatus\n      user {\n        ...userFields\n      }\n    }\n  }\n"): typeof import('./graphql').AccountCollaborationMembershipFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment projectMembershipFields on ProjectMembershipNode {\n    id\n    userRole\n    user {\n      ...userFields\n      ...userPreferences\n    }\n  }\n"): typeof import('./graphql').ProjectMembershipFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment projectMembershipList on ProjectMembershipListNode {\n    memberships {\n      totalCount\n      edges {\n        node {\n          ...projectMembershipFields\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').ProjectMembershipListFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment projectData on ProjectNode {\n    id\n    name\n    privacy\n    measurementUnits\n    description\n    siteInstructions\n    updatedAt\n    archived\n    membershipList {\n      ...projectMembershipList\n    }\n    siteSet {\n      edges {\n        node {\n          ...siteData\n        }\n      }\n    }\n    soilSettings {\n      ...projectSoilSettings\n    }\n  }\n"): typeof import('./graphql').ProjectDataFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment siteData on SiteNode {\n    id\n    name\n    latitude\n    longitude\n    elevation\n    archived\n    updatedAt\n    privacy\n    owner {\n      id\n    }\n    project {\n      id\n    }\n    notes {\n      edges {\n        node {\n          ...siteNoteData\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').SiteDataFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment siteNoteData on SiteNoteNode {\n    id\n    content\n    createdAt\n    updatedAt\n    author {\n      id\n      firstName\n      lastName\n    }\n    site {\n      id\n    }\n  }\n"): typeof import('./graphql').SiteNoteDataFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment projectSoilSettings on ProjectSoilSettingsNode {\n    depthIntervals {\n      depthInterval {\n        start\n        end\n      }\n      label\n    }\n    depthIntervalPreset\n    soilPitRequired\n    slopeRequired\n    soilTextureRequired\n    soilColorRequired\n    verticalCrackingRequired\n    carbonatesRequired\n    phRequired\n    soilOrganicCarbonMatterRequired\n    electricalConductivityRequired\n    sodiumAdsorptionRatioRequired\n    soilStructureRequired\n    landUseLandCoverRequired\n    soilLimitationsRequired\n    photosRequired\n    notesRequired\n  }\n"): typeof import('./graphql').ProjectSoilSettingsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment soilData on SoilDataNode {\n    downSlope\n    crossSlope\n    bedrock\n    depthIntervalPreset\n    slopeLandscapePosition\n    slopeAspect\n    slopeSteepnessSelect\n    slopeSteepnessPercent\n    slopeSteepnessDegree\n    surfaceCracksSelect\n    surfaceSaltSelect\n    floodingSelect\n    limeRequirementsSelect\n    surfaceStoninessSelect\n    waterTableDepthSelect\n    soilDepthSelect\n    landCoverSelect\n    grazingSelect\n    depthIntervals {\n      ...soilDataDepthInterval\n    }\n    depthDependentData {\n      ...depthDependentSoilData\n    }\n  }\n"): typeof import('./graphql').SoilDataFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment soilDataDepthInterval on SoilDataDepthIntervalNode {\n    label\n    depthInterval {\n      start\n      end\n    }\n    soilTextureEnabled\n    soilColorEnabled\n    carbonatesEnabled\n    phEnabled\n    soilOrganicCarbonMatterEnabled\n    electricalConductivityEnabled\n    sodiumAdsorptionRatioEnabled\n    soilStructureEnabled\n  }\n"): typeof import('./graphql').SoilDataDepthIntervalFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment depthDependentSoilData on DepthDependentSoilDataNode {\n    depthInterval {\n      start\n      end\n    }\n    texture\n    rockFragmentVolume\n    clayPercent\n    colorHue\n    colorValue\n    colorChroma\n    colorPhotoUsed\n    colorPhotoSoilCondition\n    colorPhotoLightingCondition\n    conductivity\n    conductivityTest\n    conductivityUnit\n    structure\n    ph\n    phTestingSolution\n    phTestingMethod\n    soilOrganicCarbon\n    soilOrganicMatter\n    soilOrganicCarbonTesting\n    soilOrganicMatterTesting\n    sodiumAbsorptionRatio\n    carbonates\n  }\n"): typeof import('./graphql').DepthDependentSoilDataFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment soilMatchInfo on SoilMatchInfo {\n    score\n    rank\n  }\n"): typeof import('./graphql').SoilMatchInfoFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment soilInfo on SoilInfo {\n    soilSeries {\n      name\n      taxonomySubgroup\n      description\n      fullDescriptionUrl\n    }\n\n    ecologicalSite {\n      name\n      id\n      url\n    }\n\n    landCapabilityClass {\n      capabilityClass\n      subClass\n    }\n\n    soilData {\n      slope\n      depthDependentData {\n        depthInterval {\n          start\n          end\n        }\n        texture\n        rockFragmentVolume\n        munsellColorString\n      }\n    }\n  }\n"): typeof import('./graphql').SoilInfoFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment soilIdFailure on SoilIdFailure {\n    reason\n  }\n"): typeof import('./graphql').SoilIdFailureFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment locationBasedSoilMatches on LocationBasedSoilMatches {\n    matches {\n      dataSource\n      distanceToNearestMapUnitM\n      match {\n        ...soilMatchInfo\n      }\n      soilInfo {\n        ...soilInfo\n      }\n    }\n  }\n"): typeof import('./graphql').LocationBasedSoilMatchesFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment dataBasedSoilMatches on DataBasedSoilMatches {\n    matches {\n      dataSource\n      distanceToNearestMapUnitM\n      locationMatch {\n        ...soilMatchInfo\n      }\n      dataMatch {\n        ...soilMatchInfo\n      }\n      combinedMatch {\n        ...soilMatchInfo\n      }\n      soilInfo {\n        ...soilInfo\n      }\n    }\n  }\n"): typeof import('./graphql').DataBasedSoilMatchesFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment groupFields on GroupNode {\n    id\n    slug\n    name\n    description\n    email\n    website\n    email\n  }\n"): typeof import('./graphql').GroupFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment groupsListFields on GroupNode {\n    slug\n    name\n  }\n"): typeof import('./graphql').GroupsListFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment groupDataEntries on GroupNode {\n    sharedResources {\n      edges {\n        node {\n          id\n          shareAccess\n          shareUrl\n          source {\n            ... on DataEntryNode {\n              ...dataEntry\n              ...dataEntryVisualizations\n            }\n          }\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').GroupDataEntriesFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment groupMembershipList on GroupNode {\n    membershipList {\n      membershipType\n      membershipsCount\n      ...accountCollaborationMembership\n    }\n  }\n"): typeof import('./graphql').GroupMembershipListFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment groupMembershipListWithMembersSample on GroupNode {\n    membershipList {\n      membershipType\n      membershipsCount\n      ...accountCollaborationMembership\n      ...collaborationMembershipsPending\n      memberships(\n        user_Email_Not: $accountEmail\n        first: 5\n        membershipStatus: APPROVED\n      ) {\n        edges {\n          node {\n            ...collaborationMembershipFields\n          }\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').GroupMembershipListWithMembersSampleFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query groupToUpdate($slug: String!) {\n      groups(slug: $slug) {\n        edges {\n          node {\n            ...groupFields\n            ...groupMembershipList\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').GroupToUpdateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query groupToView($slug: String!, $accountEmail: String!) {\n      groups(slug: $slug) {\n        edges {\n          node {\n            ...groupFields\n            ...groupDataEntries\n            ...groupMembershipListWithMembersSample\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').GroupToViewDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query groups {\n      independentGroups: groups(associatedLandscapes_Isnull: true) {\n        edges {\n          node {\n            ...groupFields\n            ...groupMembershipList\n          }\n        }\n      }\n      landscapeGroups: groups(associatedLandscapes_Isnull: false) {\n        edges {\n          node {\n            ...groupFields\n            ...groupMembershipList\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').GroupsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query groupsAutocomplete {\n      independentGroups: groups(associatedLandscapes_Isnull: true) {\n        edges {\n          node {\n            ...groupsListFields\n          }\n        }\n      }\n      landscapeGroups: groups(associatedLandscapes_Isnull: false) {\n        edges {\n          node {\n            ...groupsListFields\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').GroupsAutocompleteDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query groupForMembers($slug: String!) {\n      groups(slug: $slug) {\n        edges {\n          node {\n            ...groupFields\n            membershipList {\n              ...collaborationMemberships\n              ...accountCollaborationMembership\n            }\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').GroupForMembersDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query groupToUploadSharedData($slug: String!) {\n      groups(slug: $slug) {\n        edges {\n          node {\n            id\n            slug\n            name\n            ...groupMembershipList\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').GroupToUploadSharedDataDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation updateGroup($input: GroupUpdateMutationInput!) {\n      updateGroup(input: $input) {\n        group {\n          ...groupFields\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').UpdateGroupDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation addGroup($input: GroupAddMutationInput!) {\n      addGroup(input: $input) {\n        group {\n          ...groupFields\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').AddGroupDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation deleteGroupMembership(\n      $input: GroupMembershipDeleteMutationInput!\n      $accountEmail: String!\n    ) {\n      deleteGroupMembership(input: $input) {\n        group {\n          ...groupFields\n          ...groupDataEntries\n          ...groupMembershipListWithMembersSample\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').DeleteGroupMembershipDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation joinGroup(\n      $input: GroupMembershipSaveMutationInput!\n      $accountEmail: String!\n    ) {\n      saveGroupMembership(input: $input) {\n        group {\n          ...groupFields\n          ...groupDataEntries\n          ...groupMembershipListWithMembersSample\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').JoinGroupDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation leaveGroupFromList($input: GroupMembershipDeleteMutationInput!) {\n      deleteGroupMembership(input: $input) {\n        group {\n          ...groupFields\n          ...groupMembershipList\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').LeaveGroupFromListDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation joinGroupFromListPage($input: GroupMembershipSaveMutationInput!) {\n      saveGroupMembership(input: $input) {\n        group {\n          ...groupFields\n          ...groupMembershipList\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').JoinGroupFromListPageDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation changeGroupMemberRole($input: GroupMembershipSaveMutationInput!) {\n      saveGroupMembership(input: $input) {\n        memberships {\n          ...collaborationMembershipFields\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').ChangeGroupMemberRoleDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation removeGroupMember($input: GroupMembershipDeleteMutationInput!) {\n      deleteGroupMembership(input: $input) {\n        membership {\n          ...collaborationMembershipFields\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').RemoveGroupMemberDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query home($accountEmail: String!) {\n      landscapes(membershipList_Memberships_User_Email: $accountEmail) {\n        edges {\n          node {\n            ...landscapeFields\n            ...landscapeMembershipList\n          }\n        }\n      }\n      userIndependentGroups: groups(\n        memberships_Email: $accountEmail\n        associatedLandscapes_Isnull: true\n      ) {\n        edges {\n          node {\n            ...groupFields\n            membershipList {\n              ...collaborationMembershipsPending\n              ...accountCollaborationMembership\n            }\n          }\n        }\n      }\n      userLandscapeGroups: groups(\n        memberships_Email: $accountEmail\n        associatedLandscapes_Isnull: false\n      ) {\n        edges {\n          node {\n            ...groupFields\n            membershipList {\n              ...collaborationMembershipsPending\n              ...accountCollaborationMembership\n            }\n          }\n        }\n      }\n      storyMaps(memberships_User_Email: $accountEmail) {\n        edges {\n          node {\n            ...storyMapMetadataFields\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').HomeDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment landscapeFields on LandscapeNode {\n    id\n    slug\n    name\n    location\n    description\n    email\n    website\n    profileImage\n  }\n"): typeof import('./graphql').LandscapeFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment landscapeProfileFields on LandscapeNode {\n    id\n    slug\n    name\n    location\n    description\n    email\n    website\n    areaScalarHa\n    areaTypes\n    population\n    partnershipStatus\n    profileImage\n    profileImageDescription\n    taxonomyTerms {\n      edges {\n        node {\n          type\n          valueOriginal\n          ...taxonomyTermLanguages\n        }\n      }\n    }\n    associatedGroups {\n      edges {\n        node {\n          isPartnership\n          partnershipYear\n          group {\n            slug\n            name\n          }\n        }\n      }\n    }\n    associatedDevelopmentStrategy {\n      edges {\n        node {\n          objectives\n          opportunities\n          problemSitutation\n          interventionStrategy\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').LandscapeProfileFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment landscapePartnershipField on LandscapeNode {\n    partnershipStatus\n    associatedGroups(isPartnership: true) {\n      edges {\n        node {\n          isPartnership\n          partnershipYear\n          group {\n            slug\n            name\n          }\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').LandscapePartnershipFieldFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment landscapeMembershipList on LandscapeNode {\n    membershipList {\n      membershipsCount\n      ...accountCollaborationMembership\n    }\n  }\n"): typeof import('./graphql').LandscapeMembershipListFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment landscapeMembershipListWithMembersSample on LandscapeNode {\n    membershipList {\n      membershipsCount\n      ...accountCollaborationMembership\n      memberships(\n        user_Email_Not: $accountEmail\n        first: 5\n        membershipStatus: APPROVED\n      ) {\n        edges {\n          node {\n            ...collaborationMembershipFields\n          }\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').LandscapeMembershipListWithMembersSampleFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment landscapeDataEntries on LandscapeNode {\n    sharedResources {\n      edges {\n        node {\n          id\n          shareAccess\n          shareUrl\n          source {\n            ... on DataEntryNode {\n              ...dataEntry\n              ...dataEntryVisualizations\n            }\n          }\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').LandscapeDataEntriesFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query landscapesToUpdate($slug: String!) {\n      landscapes(slug: $slug) {\n        edges {\n          node {\n            ...landscapeProfileFields\n            areaPolygon\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').LandscapesToUpdateDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query landscapesToView($slug: String!, $accountEmail: String!) {\n      landscapes(slug: $slug) {\n        edges {\n          node {\n            ...landscapeFields\n            ...landscapePartnershipField\n            ...landscapeDataEntries\n            ...landscapeMembershipListWithMembersSample\n            areaPolygon\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').LandscapesToViewDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query landscapesProfiles($slug: String!) {\n      landscapes(slug: $slug) {\n        edges {\n          node {\n            ...landscapeProfileFields\n            ...landscapeMembershipList\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').LandscapesProfilesDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query landscapesToUploadSharedData($slug: String!) {\n      landscapes(slug: $slug) {\n        edges {\n          node {\n            ...landscapeFields\n            ...landscapeMembershipList\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').LandscapesToUploadSharedDataDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query landscapes {\n      landscapes {\n        edges {\n          node {\n            ...landscapeFields\n            ...landscapeMembershipList\n            centerCoordinates {\n              lat\n              lng\n            }\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').LandscapesDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query landscapesForMembers($slug: String!) {\n      landscapes(slug: $slug) {\n        edges {\n          node {\n            ...landscapeFields\n            membershipList {\n              ...collaborationMemberships\n              ...accountCollaborationMembership\n            }\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').LandscapesForMembersDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation updateLandscape($input: LandscapeUpdateMutationInput!) {\n      updateLandscape(input: $input) {\n        landscape {\n          ...landscapeProfileFields\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').UpdateLandscapeDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation addLandscape($input: LandscapeAddMutationInput!) {\n      addLandscape(input: $input) {\n        landscape {\n          ...landscapeProfileFields\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').AddLandscapeDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation deleteLandscapeMembership(\n      $input: LandscapeMembershipDeleteMutationInput!\n      $accountEmail: String!\n    ) {\n      deleteLandscapeMembership(input: $input) {\n        landscape {\n          ...landscapeFields\n          ...landscapePartnershipField\n          ...landscapeDataEntries\n          ...landscapeMembershipListWithMembersSample\n          areaPolygon\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').DeleteLandscapeMembershipDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation joinLandscape(\n      $input: LandscapeMembershipSaveMutationInput!\n      $accountEmail: String!\n    ) {\n      saveLandscapeMembership(input: $input) {\n        landscape {\n          ...landscapeFields\n          ...landscapePartnershipField\n          ...landscapeDataEntries\n          ...landscapeMembershipListWithMembersSample\n          areaPolygon\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').JoinLandscapeDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation leaveLandscapeFromList(\n      $input: LandscapeMembershipDeleteMutationInput!\n    ) {\n      deleteLandscapeMembership(input: $input) {\n        landscape {\n          ...landscapeFields\n          ...landscapeMembershipList\n          centerCoordinates {\n            lat\n            lng\n          }\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').LeaveLandscapeFromListDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation joinLandscapeFromList(\n      $input: LandscapeMembershipSaveMutationInput!\n    ) {\n      saveLandscapeMembership(input: $input) {\n        landscape {\n          ...landscapeFields\n          ...landscapeMembershipList\n          centerCoordinates {\n            lat\n            lng\n          }\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').JoinLandscapeFromListDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation changeMemberRole($input: LandscapeMembershipSaveMutationInput!) {\n      saveLandscapeMembership(input: $input) {\n        memberships {\n          ...collaborationMembershipFields\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').ChangeMemberRoleDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation removeMember($input: LandscapeMembershipDeleteMutationInput!) {\n      deleteLandscapeMembership(input: $input) {\n        membership {\n          ...collaborationMembershipFields\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').RemoveMemberDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment dataEntry on DataEntryNode {\n    id\n    name\n    description\n    entryType\n    resourceType\n    url\n    size\n    createdAt\n    createdBy {\n      id\n      lastName\n      firstName\n    }\n  }\n"): typeof import('./graphql').DataEntryFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment dataEntryVisualizations on DataEntryNode {\n    visualizations {\n      edges {\n        node {\n          ...visualizationConfig\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').DataEntryVisualizationsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment visualizationConfig on VisualizationConfigNode {\n    id\n    readableId\n    title\n    description\n    slug\n    createdAt\n    createdBy {\n      id\n      lastName\n      firstName\n    }\n  }\n"): typeof import('./graphql').VisualizationConfigFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment visualizationConfigWithConfiguration on VisualizationConfigNode {\n    ...visualizationConfig\n    mapboxTilesetId\n    mapboxTilesetStatus\n    configuration\n  }\n"): typeof import('./graphql').VisualizationConfigWithConfigurationFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation deleteSharedData($id: ID!) {\n      deleteDataEntry(input: { id: $id }) {\n        dataEntry {\n          id\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').DeleteSharedDataDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation addDataEntry($input: DataEntryAddMutationInput!) {\n      addDataEntry(input: $input) {\n        dataEntry {\n          id\n          name\n          url\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').AddDataEntryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation updateSharedData($input: DataEntryUpdateMutationInput!) {\n      updateDataEntry(input: $input) {\n        dataEntry {\n          ...dataEntry\n          visualizations {\n            edges {\n              node {\n                ...visualizationConfigWithConfiguration\n              }\n            }\n          }\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').UpdateSharedDataDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query dataEntries(\n      $slug: String!\n      $type: String!\n      $resourceTypes: [String]!\n    ) {\n      dataEntries(\n        sharedResources_Target_Slug: $slug\n        sharedResources_TargetContentType: $type\n        resourceType_In: $resourceTypes\n      ) {\n        edges {\n          node {\n            ...dataEntry\n            ...dataEntryVisualizations\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').DataEntriesDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query dataEntryWithGeojson($id: ID!) {\n      dataEntry(id: $id) {\n        ...dataEntry\n        geojson\n      }\n    }\n  "): typeof import('./graphql').DataEntryWithGeojsonDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation addVisualizationConfig(\n      $input: VisualizationConfigAddMutationInput!\n    ) {\n      addVisualizationConfig(input: $input) {\n        visualizationConfig {\n          slug\n          readableId\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').AddVisualizationConfigDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation deleteVisualizationConfig($id: ID!) {\n      deleteVisualizationConfig(input: { id: $id }) {\n        visualizationConfig {\n          ...visualizationConfig\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').DeleteVisualizationConfigDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query fetchVisualizationConfig(\n      $ownerSlug: String!\n      $ownerType: String!\n      $configSlug: String!\n      $readableId: String!\n    ) {\n      visualizationConfigs(\n        dataEntry_SharedResources_Target_Slug: $ownerSlug\n        dataEntry_SharedResources_TargetContentType: $ownerType\n        slug: $configSlug\n        readableId: $readableId\n      ) {\n        edges {\n          node {\n            ...visualizationConfigWithConfiguration\n            dataEntry {\n              ...dataEntry\n            }\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').FetchVisualizationConfigDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation updateSharedResource($input: SharedResourceUpdateMutationInput!) {\n      updateSharedResource(input: $input) {\n        sharedResource {\n          id\n          shareAccess\n          shareUrl\n          source {\n            ... on DataEntryNode {\n              ...dataEntry\n              ...dataEntryVisualizations\n            }\n          }\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').UpdateSharedResourceDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query sharedResource($shareUuid: String!) {\n      sharedResource(shareUuid: $shareUuid) {\n        downloadUrl\n        source {\n          ... on DataEntryNode {\n            ...dataEntry\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').SharedResourceDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment storyMapFields on StoryMapNode {\n    id\n    slug\n    storyMapId\n    title\n    isPublished\n    configuration\n    createdAt\n    updatedAt\n    publishedAt\n    createdBy {\n      ...userFields\n    }\n    membershipList {\n      ...collaborationMemberships\n      ...accountCollaborationMembership\n    }\n  }\n"): typeof import('./graphql').StoryMapFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment storyMapMetadataFields on StoryMapNode {\n    id\n    slug\n    storyMapId\n    title\n    isPublished\n    createdAt\n    updatedAt\n    publishedAt\n    createdBy {\n      id\n      lastName\n      firstName\n    }\n    membershipList {\n      membershipsCount\n      ...accountCollaborationMembership\n      memberships(user_Email_Not: $accountEmail, first: 2) {\n        edges {\n          node {\n            ...collaborationMembershipFields\n          }\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').StoryMapMetadataFieldsFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query storyMapsHome($accountEmail: String!) {\n      samples: storyMaps(memberships_User_Email_Not: $accountEmail) {\n        edges {\n          node {\n            ...storyMapMetadataFields\n          }\n        }\n      }\n      userStoryMaps: storyMaps(memberships_User_Email: $accountEmail) {\n        edges {\n          node {\n            ...storyMapMetadataFields\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').StoryMapsHomeDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query fetchStoryMap($slug: String!, $storyMapId: String!) {\n      storyMaps(slug: $slug, storyMapId: $storyMapId) {\n        edges {\n          node {\n            ...storyMapFields\n            membershipList {\n              ...collaborationMemberships\n              ...accountCollaborationMembership\n            }\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').FetchStoryMapDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation deleteStoryMap($id: ID!) {\n      deleteStoryMap(input: { id: $id }) {\n        storyMap {\n          id\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').DeleteStoryMapDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation addMemberships($input: StoryMapMembershipSaveMutationInput!) {\n      saveStoryMapMembership(input: $input) {\n        memberships {\n          ...collaborationMembershipFields\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').AddMembershipsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation deleteMembership($input: StoryMapMembershipDeleteMutationInput!) {\n      deleteStoryMapMembership(input: $input) {\n        membership {\n          id\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').DeleteMembershipDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation approveMembership(\n      $accountEmail: String!\n      $input: StoryMapMembershipApproveMutationInput!\n    ) {\n      approveStoryMapMembership(input: $input) {\n        membership {\n          id\n        }\n        storyMap {\n          ...storyMapMetadataFields\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').ApproveMembershipDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation approveMembershipToken(\n      $accountEmail: String!\n      $input: StoryMapMembershipApproveTokenMutationInput!\n    ) {\n      approveStoryMapMembershipToken(input: $input) {\n        membership {\n          id\n        }\n        storyMap {\n          ...storyMapMetadataFields\n        }\n        errors\n      }\n    }\n  "): typeof import('./graphql').ApproveMembershipTokenDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query visualizationConfigs {\n      visualizationConfigs {\n        edges {\n          node {\n            ...visualizationConfigWithConfiguration\n            dataEntry {\n              name\n              resourceType\n              createdBy {\n                lastName\n                firstName\n              }\n              sharedResources {\n                edges {\n                  node {\n                    target {\n                      ... on GroupNode {\n                        name\n                        membershipList {\n                          membershipType\n                        }\n                      }\n                      ... on LandscapeNode {\n                        name\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').VisualizationConfigsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment taxonomyTermLanguages on TaxonomyTermNode {\n    valueEn\n    valueEs\n  }\n"): typeof import('./graphql').TaxonomyTermLanguagesFragmentDoc;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query taxonomyTerms($types: [CoreTaxonomyTermTypeChoices]!) {\n      taxonomyTerms(type_In: $types) {\n        edges {\n          node {\n            slug\n            type\n            valueOriginal\n            ...taxonomyTermLanguages\n          }\n        }\n      }\n    }\n  "): typeof import('./graphql').TaxonomyTermsDocument;


export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}
